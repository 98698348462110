// 250120 개인정보처리방침

import { Component } from "react";

// 다국어 설정
import RegexText from "../../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../../context/LocaleContext";

export default class Term06 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(context) => {
          const lc = context.lc;

          return (
                <>
                    <div className='TermsText'>{lc.policy3.subtitle}</div>
                    <div className='TextBox'>
                        <div className='Text01'>{lc.policy3.text_1}</div>
                        <div className='Text02'>{lc.policy3.text_2}</div>
                    </div>
                    <div className='LineG' />

                    {/* 1. 개인정보의 처리목적 및 수집항목, 보유 및 이용 기간 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_1}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_1_1}</div>
                            <table className="Horizontal">
                                <colgroup>
                                    <col width="" />
                                    <col width="" />
                                    <col width="" />
                                    <col width="" />
                                </colgroup>
                                <tr>
                                    <th>{lc.policy3.content_1_th1}</th>
                                    <th>{lc.policy3.content_1_th2}</th>
                                    <th>{lc.policy3.content_1_th3}</th>
                                    <th>{lc.policy3.content_1_th4}</th>
                                </tr>
                                <tr>
                                    <td rowSpan={2} className="vm">{lc.policy3.content_1_td1_1}</td>
                                    <td className="vm">{lc.policy3.content_1_td1_2}</td>
                                    <td className="vm">{lc.policy3.content_1_td1_3}</td>
                                    <td className="vm">{lc.policy3.content_1_td1_4}</td>
                                </tr>
                                <tr>
                                    <td className="vm">{lc.policy3.content_1_td2_1}</td>
                                    <td className="vm">{lc.policy3.content_1_td2_2}</td>
                                    <td className="vm">{lc.policy3.content_1_td2_3}</td>
                                </tr>
                                <tr>
                                    <td rowSpan={2} className="vm">{lc.policy3.content_1_td3_1}</td>
                                    <td className="vm">{lc.policy3.content_1_td3_2}</td>
                                    <td className="vm">{lc.policy3.content_1_td3_3}</td>
                                    <td className="vm">{lc.policy3.content_1_td3_4}</td>
                                </tr>
                                <tr>
                                    <td className="vm">{lc.policy3.content_1_td4_1}</td>
                                    <td className="vm">{lc.policy3.content_1_td4_2}</td>
                                    <td className="vm">{lc.policy3.content_1_td4_3}</td>
                                </tr>
                                <tr>
                                    <td className="vm">{lc.policy3.content_1_td5_1}</td>
                                    <td className="vm">{lc.policy3.content_1_td5_2}</td>
                                    <td className="vm">{lc.policy3.content_1_td5_3}</td>
                                    <td className="vm">{lc.policy3.content_1_td5_4}</td>
                                </tr>
                            </table>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='SmallText'>{lc.policy3.content_1_2}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_1}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_2}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_3}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_4}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_5}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_6}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_7}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_1_2_8}</div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 2. 개인정보의 수집방법 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_2}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_2_1}</div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_2_1_1}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_2_1_2}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_2_1_3}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_2_1_4}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_2_1_5}</div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 3. 개인정보의 제3자 제공에 관한 사항 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_3}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_3_1}</div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_3_1_1}</div>
                            </div>                            
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_3_1_2}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_3_1_3}</div>
                            </div>
                            <div className='Text01 mt20'>{lc.policy3.content_3_1_4}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 4. 개인정보처리 위탁 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_4}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_4_1}</div>
                            <table className="Horizontal">
                                <colgroup>
                                    <col width="24%" />
                                    <col width="36%" />
                                    <col width="40%" />
                                </colgroup>
                                <tr>
                                    <th>{lc.policy3.content_4_th1}</th>
                                    <th>{lc.policy3.content_4_th2}</th>
                                    <th>{lc.policy3.content_4_th3}</th>
                                </tr>
                                <tr>
                                    <td>{lc.policy3.content_4_td1_1}</td>
                                    <td>{lc.policy3.content_4_td1_2}</td>
                                    <td>{lc.policy3.content_4_td1_3}</td>
                                </tr>
                            </table>
                            <div className='Text01 mt20'>{lc.policy3.content_4_2}</div>
                            <div className='Text01 mt20'>{lc.policy3.content_4_3}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 5. 개인정보 국외 이전 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_5}</div>
                        <div className='TextSmallBox'>
                        <div className='Text01 h-box mt20'>{RegexText(lc.policy3.content_5_1, "mr10")}</div>
                            <table className="ml26">
                                <colgroup>
                                    <col width="250px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>{lc.policy3.content_5_th1}</th>
                                    <td>{lc.policy3.content_5_td1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_5_th2}</th>
                                    <td>{lc.policy3.content_5_td2}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_5_th3}</th>
                                    <td>{lc.policy3.content_5_td3}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_5_th4}</th>
                                    <td>{lc.policy3.content_5_td4}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_5_th5}</th>
                                    <td>{lc.policy3.content_5_td5}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_5_th6}</th>
                                    <td>{lc.policy3.content_5_td6}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_5_th7}</th>
                                    <td>{lc.policy3.content_5_td7}</td>
                                </tr>
                            </table>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='SmallText'>{lc.policy3.content_5_2}</div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 6. 정보주체의 권리,의무 및 그 행사방법 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_6}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_6_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_6_1_1, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_6_1_2, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_6_1_3, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_6_1_4, "mr10")}</div>                            
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 7. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_7}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_7_1}</div>
                            <div className='Text01 h-box mt20'>{RegexText(lc.policy3.content_7_2, "mr10")}</div>
                            <div className='Text01'>{lc.policy3.content_7_2_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_7_3, "mr10")}</div>  
                            <div className='Text01'>{lc.policy3.content_7_3_1}</div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_7_3_2}</div>
                            </div>   
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_7_3_3}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_7_3_4}</div>
                            </div>                  
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 8. 개인정보 파기 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_8}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_8_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_8_1_1, "mr10")}</div>
                            <div className='Text01'>{lc.policy3.content_8_1_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_8_2_1, "mr10")}</div>  
                            <div className='Text01'>{lc.policy3.content_8_2_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_8_3_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy3.content_8_3_2}</div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_8_3_3}</div>
                            </div>   
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy3.content_8_3_4}</div>
                            </div>                  
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 9. 개인정보의 안정성 확보 조치 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_9}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_9_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_9_1_1, "mr10")}</div>
                            <div className='Text01'>{lc.policy3.content_9_1_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_9_2_1, "mr10")}</div>  
                            <div className='Text01'>{lc.policy3.content_9_2_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_9_3_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy3.content_9_3_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_9_4_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy3.content_9_4_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_9_5_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy3.content_9_5_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_9_6_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy3.content_9_6_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_9_7_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy3.content_9_7_2}</div>                                       
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 10. 개인정보 보호 책임자 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_10}</div>                        
                        <div className='TextSmallBox'>
                            <div className='Text01 h-box'>{RegexText(lc.policy3.content_10_1, "mr10")}</div>
                            <div className='TextBlue'>{lc.policy3.content_10_2}</div>
                            <table className="ml26">
                                <tr>
                                    <th>{lc.policy3.content_10_th1}</th>
                                    <td>{lc.policy3.content_10_td1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th2}</th>
                                    <td>{lc.policy3.content_10_td2}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th3}</th>
                                    <td>{lc.policy3.content_10_td3}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th4}</th>
                                    <td>{lc.policy3.content_10_td4}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th5}</th>
                                    <td>{lc.policy3.content_10_td5}</td>
                                </tr>
                            </table>

                            <div className='TextBlue'>{lc.policy3.content_10_3}</div>
                            <table className="ml26">
                                <tr>
                                    <th>{lc.policy3.content_10_th6}</th>
                                    <td>{lc.policy3.content_10_td6}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th7}</th>
                                    <td>{lc.policy3.content_10_td7}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th8}</th>
                                    <td>{lc.policy3.content_10_td8}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th9}</th>
                                    <td>{lc.policy3.content_10_td9}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy3.content_10_th10}</th>
                                    <td>{lc.policy3.content_10_td10}</td>
                                </tr>
                            </table>
                            <div className='Text01 h-box mt30'>{RegexText(lc.policy3.content_10_4, "mr10")}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                    {/* 11.  권익 침해 구제방법 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_11}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy3.content_11_1}</div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy3.content_11_2}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                    {lc.policy3.content_11_3}
                                </div>
                            </div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy3.content_11_4}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                {   lc.policy3.content_11_5}
                                </div>
                            </div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy3.content_11_6}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                    {lc.policy3.content_11_7}
                                </div>
                            </div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy3.content_11_8}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                    {lc.policy3.content_11_9}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />
                    
                    {/* 12. 개인정보 처리방침의 변경 */}
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy3.title_12}</div>
                        <div className='TextSmallBox'>
                                <div className='Text01'>{lc.policy3.content_12_1}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                </>
            );
        }}
      </LocaleConsumer>
    );
  }
}
